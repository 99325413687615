import {
  UNSPLASH_LIKES_FROM_USER,
  UNSPLASH_PHOTOS_FROM_USER,
  UNSPLASH_RANDOM_PHOTO,
  UNSPLASH_DOWNLOAD_PHOTO
} from "./unsplash.constants";

const initialState = {
  downloadPhoto: false,
  images: [],
  isFetching: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UNSPLASH_LIKES_FROM_USER: {
      return Object.assign({}, state, {
        isFetching: false,
        images: payload
      });
    }
    case UNSPLASH_PHOTOS_FROM_USER: {
      return Object.assign({}, state, {
        isFetching: false,
        images: payload
      });
    }
    case UNSPLASH_RANDOM_PHOTO: {
      return Object.assign({}, state, {
        downloadPhoto: false,
        isFetching: false,
        images: payload
      });
    }
    case UNSPLASH_DOWNLOAD_PHOTO: {
      return Object.assign({}, state, {
        downloadPhoto: true
      });
    }
    default:
      return state;
  }
}
