import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Add GoogleAnalytics tracking
import withTracker from "./withTracker";

// Views
const Container = React.lazy(() => import("../app/"));
const Home = React.lazy(() => import("../views/home"));

// ErrorHandling
const Error404 = React.lazy(() => import("../views/error/404"));

export default (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Container>
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />
          <Route path="/4k/:collection" component={withTracker(Home)} />
          <Route path="/:collection" component={withTracker(Home)} />
          <Route component={withTracker(Error404)} />
        </Switch>
      </Container>
    </Suspense>
  </Router>
);
