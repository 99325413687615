import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  lng: "en",
  // Using simple hardcoded resources for simple example
  resources: {
    en: {
      translation: {
        error: {
          title: "Oops, something went wrong!",
          description:
            "Go back and please try again. In case you keep coming back to this page, please try again later."
        },
        global: {
          goodafternoon: "Good afternoon!",
          goodevening: "Good evening!",
          goodmorning: "Good morning!"
        },
        home: {
          title: "Welcome!",
          unsplashCredits: "Photo from Unsplash by:"
        }
      }
    },
    nl: {
      translation: {
        error: {
          title: "Oops, er is iets mis gegaan",
          description:
            "Ga terug en probeer het opnieuw. Als je deze pagina blijft zien, probeer het dan op een later moment."
        },
        global: {
          goodafternoon: "Fijne middag!",
          goodevening: "Fijne avond!",
          goodmorning: "Goedemorgen!"
        },
        home: {
          title: "Welkom!",
          unsplashCredits: "Foto van Unsplash door:"
        }
      }
    }
  }
});

export default i18next;
