import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers';

// https://github.com/zalmoxisus/redux-devtools-extension
/* eslint-disable no-underscore-dangle */
const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable */

const composeEnhancers = process.env.NODE_ENV !== 'production' && devTools ? devTools : compose;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
};

export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, rootReducer(history));
export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
    ),
  ),
);
export const persistor = persistStore(store);
