import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// locals
import unsplash from './unsplash/unsplash.reducer';

export default history => combineReducers({
  unsplash,
  router: connectRouter(history),
});
